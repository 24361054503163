var swiper = new Swiper('.swiper-container', {
    pagination: '.swiper-pagination',
    paginationClickable: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    spaceBetween: 30
});

var inscroll = false;
var margin = 40;
$(document).scroll(function () {


    if ($(this).scrollTop() > margin && inscroll == false) {
        $('body').addClass('isScrolling')
        inscroll = true;
    } else if (inscroll == true && $(this).scrollTop() < margin) {
        inscroll = false;
        $('body').removeClass('isScrolling')
    }
    if ( $(this).scrollTop() > margin) {
        $('.navbar').addClass('navbar--scrolling');
    } else {
        $('.navbar').removeClass('navbar--scrolling');
    }
});
new WOW({offset: '300'}).init();

$('form').parsley();

APP = {};
APP.smoth = function () {
    var target;
    $('a[href*="#"]:not([href="#"])').click(function () {
        var target;
        if ($(this).data('disable-hash')) {
            return this;
        }
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            target = $(this.hash);
            if (target.length) {
                return $('html,body').animate({
                    scrollTop: target.offset().top - 75
                }, 1000);
            }
        }
    });
    if (window.location.hash) {
        target = $('#' + window.location.hash.replace('#', ''));
        return setTimeout(function () {
            if (target.length) {
                return $('html,body').animate({
                    scrollTop: target.offset().top - 75
                }, 1000);
            }
        }, 1000);
    }
};
$(document).ready(function(){
    APP.smoth()
})